h1 {
    font-family: var(--font-sans);
    font-weight: 500;
    color: var(--dark);
    text-transform: capitalize;
}

h3 {
    font-family: var(--font-sans);
    color: var(--dark);
    font-size: 1.5rem;

    a {
        color: var(--accent);
        text-decoration: none;
    }
} 

h4 {
    font-family: var(--font-sans);
    margin: 0;
    color: var(--dark);
}

text-primary {
    color: var(--primary);
}

p {
    font-family: var(--font-sans);
    margin: 0;
    color: var(--dark);

    a {
        color: inherit;
        text-decoration: none;
    }
}

p.hero-text {
    color: var(--dark);
    font-size: 5rem;
    font-family: var(--font-serif);
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

h1.headline-text {
    font-size: 3rem;
}

h2.description-text {
    font-size: 1.5rem;
    font-family: var(--font-sans);
    font-weight: 300;
}

.font-sans {
    font-family: var(--font-sans);
    font-weight: 300;
    font-style: normal;
}

.font-sans {
    font-family: var(--font-sans);
    font-optical-sizing: auto;
    font-style: normal;
}

p.small {
    font-size: 0.75rem;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

@media screen and (max-width: 550px) {
    .empty-class {
        margin: 0;
    }
}